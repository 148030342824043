@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&Inter&family=Outfit&family=Roboto:wght@400;700&display=swap');

$login-btn-color: #F3C121;
$canteen-setting-btn-color: #018C4E;
$canteen-setting-bg-color: #B2DCC9;
$canteen-setting-border-color-selected: #F08324;
$dashboard-bg-color: #EE4447;
$unsold-meals-bg-color: #B5CAE3;
$statistics-bg-color: #0B50A4;
$menus-bg-color: #99d1b8;
$communications-bg-color: #d3c4de;
$community-bg-color: #fde49b;
$profile-bg-color: #E984B7;

@mixin verticalCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

a{
    display: flex;
    flex: 1;
}

body {
    background: #fff;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;
}

li {
    list-style-type: none;
}

.full-logo{
    width: 345px;
    height: 90px;
    object-fit: cover;
}

.dashboard-body, .statistics-body{
    background-color: #F5F4ED;
    display: flex;
    flex: 1;
    width: 100%;
    padding-top: 30px;
    flex-direction: column;
}

.statistics-body{
    padding: 0px 35px;
}

.statistic-button {
    border-radius: 16px;
    background-color: rgba(1, 140, 78, 0.15);
    min-width: 100px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 5px 10px;

    &.meal {
        background-color: rgba(233, 132, 183, 0.15);
    }

    &.review {
        background-color: rgba(243, 193, 33, 0.35);
    }
}

.dashboard-resume{
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background: #FFF;
    justify-content: center;
    align-items: center;
    padding: 25px 30px;
    margin: 20px 8px 20px 8px;
    //width: 143px;
    height: 180px;
    text-align: center;
    justify-content: space-between;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.dashboard-table{
    border-radius: 20px;
    background: #FFF;
    margin: 20px 8px 20px 8px;
    display: flex;
    flex: 2;
    flex-direction: column;
    width: calc(100% - 20px);
    padding-top: 10px;

    .MuiTableContainer-root {
        border-radius: 20px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
}

.attendance-table {
    .search {
        position: absolute;
        margin-top: 10px;
        margin-left: 30px;
        border-radius: 10px;
        background-color: rgba(243, 193, 33, 0.20);
        border: none;
        padding: 5px 10px;
    }

    .point {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;

        &.reserved {
            background-color: #018C4E;
        }
        &.not-here {
            background-color: #F3C121;
        }
        &.not-reserved {
            background-color: #EE4447;
        }
    }
}

.calendar-bg {
    color: #fff;
}

.dashboard ul.menu-items, .statistics ul.menu-items{
    color: white;
}

.dashboard .menu-items-block .menu-item.menu-item__active, .statistics .menu-items-block .menu-item.menu-item__active{
    border-bottom: 2px solid white;
    font-weight: 800;
}

.label-icon{
    margin-left: 15px;
}

.label-full{
    margin-bottom: 10px;
}

.dashboard-resume-full{
    //width: 308px;
    height: 180px;
    padding: 20px;
}

.dashboard-affluence {
    width: 308px;
    height: 400px;
}

.dashboard-selection-number{
    font-size: 20px;
    font-weight: 600;
    margin-right: 10px;
}

.dashboard-selection-label{
    color: #635C5C;
    font-size: 1vw;
    font-weight: 600;
}

.dashboard-selection-count{
    font-size: 20px;
    font-weight: 900;
}

.dashboard-resume:first-child{
    margin-left: 15px;
}

.dashboard-resume span.number, .dashboard-resume div.number{
    font-weight: 900;
    line-height: 36px;
    font-size: 40px;
}

.dashboard-resume span.label, .dashboard-resume div.label{
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
}

.dashboard-resume span.label-green, .dashboard-resume div.label-green{
    color: #018C4E;
}

.dashboard-resume span.evolution{
    margin-top: 14px;
    font-size: 14px;
    font-family: Inter;
    font-weight: 600;
    line-height: 36px;
}

.fa-star{
    color: #F3C121;
}

.dashboard-heading, .statistics-heading{
    font-size: 36px;
    letter-spacing: -0.32px;
    justify-content: center;
    display: flex;
    width: 100%;
}

.dashboard-heading a{
    flex: initial;
    color: #C4C4C4;

    &:hover, &.active {
        text-decoration: underline;
        color: #000;
    }
}

.select-canteen {
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 20px;
    border: 1px solid #C4C4C4;
    min-width: 200px;
}


.div-change-canteen {
    flex: 1 1;
}

.statistics-heading{
    padding: 30px 0px;
    border-bottom: 1px solid #C4C4C4;
}

.dashboard-title{
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.32px;
    margin-top: 50px;
    display:flex;
    justify-content:center;
}

.calendar-bg{
    background-color: #EE4447;
}

.admin-dashboard .calendar-bg{
    background-color: $canteen-setting-btn-color;
}

.admin-dashboard .dashboard-block {
    display: block;
    background-color: #F5F4ED;

    .dashboard-resume {
        width: 200px;
    }
}

.calendar-day{
    background-color: white;
    padding: 0px 10px;
    border-radius: 5px;
    width: 82px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.calenday-day-name{
    display: flex;
    font-size: 14px;
    font-weight: 900;
}

.calenday-day-number{
    display: flex;
    font-size: 16px;
    font-weight: 900;
}

.calenday-day-month{
    display: flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
}

.current-day{
    margin-top: 3px;
    background-color: #F5F4ED;
    justify-content: center;
    align-items: center;
    max-width: 150px;
}

.day-before{
    background-color: #D9D9D9;
}

.day-closed{
    color: #887E7E;
    background: #D9D9D9;
}

.stat-progress-bar{
    background-color: #F3C121;
}

.stat-progress{
    text-align: center;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
}

a{
    color: inherit;
    text-decoration: inherit;
}

.dashboard-content, .statistics-content{
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.statistics-content{
    margin: 20px 0;
}

.dashboard-content div.summary{
    display: flex;
    flex-direction: column;
}

.dashboard-header-mobile, .statistics-header-mobile{
    display: none;
    background-color: #EE4447;
    width: 100%;
}

.statistics-header-mobile{
    background-color: #F3C121;
}

.global-stat .statistics-header-mobile{
    background-color: #0B50A4;
}

.dashboard-header-menu, .statistics-header-menu{
    display: flex;
    padding: 5px 0px 5px 30px;
    cursor: pointer;
}

.dashboard-header-name, .statistics-header-name{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-weight: 900;
}

.dashboard-header-avatar, .statistics-header-avatar{
    display: flex;
    padding: 5px 30px 5px 0;
}

.dashboard-chart, .statistics-chart{
    display: flex;
    min-width: 300px;
    min-height: 450px;
}

.statistics-chart-global-content{
    width: 530px;
    display: flex;
    flex-wrap: wrap;
}

.meal-charts-legend-text{
    color: #848A9C;
    font-family: Nunito Sans;
    font-size: 20px;
}

.statistic-details{
    width: 245px;
    margin: 10px;
    height: 150px;
    border-radius: 16px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.statistic-details-money{
    background: rgba(1, 140, 78, 0.15);
}

.statistic-details-meal{
    background: rgba(233, 132, 183, 0.15);
    width: 300px;
}

.statistics .statistic-details-review{
    background: rgba(243, 193, 33, 0.35);
}

.statistic-detail-title{
    font-size: 20px;
    font-weight: 600;
    padding: 5px 0;
}

.statistic-detail-value, .statistic-meal-detail-value{
    font-size: 40px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.statistic-detail-meal{
    font-size: 30px;
}

.statistic-detail-meal span:nth-child(2){
    font-size: 1vw;
}

.statistic-meal-detail-value{
    font-size: 20px;
    color: #E984B7;
}

.statistic-detail-value img, .statistic-meal-detail-value img{
    width: 40px;
    height: 40px;
    margin-right: 20px
}

.statistic-detail-meal img{
    margin-right: 10px;
}

.statistic-detail-evolution{
    font-size: 20px;
    font-weight: 600;
    padding: 5px 0;
}

.statistics-sub-heading{
    display: flex;
    margin-top: 40px;
    margin-bottom: 30px;
}

.statistics-sub-heading-text{
    font-size: 24px;
    display: flex;
    flex: 1;
    justify-content: center;
}

.statistics-sub-heading-active{
    font-weight: 900;
    text-decoration-line: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 8px;
}

.statistics-overview{
    background-color: white;
    border-radius: 50px;
    padding: 25px 15px;
    display: flex;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.statistics-content-blur{
    filter: blur(10px);
}

.statistics-offer-upgrade{
    position: absolute;
    top: 470px;
    text-align: center;
    margin: 0 22% 0 20%;
    width: 45%;
    justify-content: center;
    background-color: white;
    border-radius: 50px;
    border: 1px solid #635C5C;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 45px;
}

.statistics-offer-title{
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 40px;
}

.statistics-offer-subtitle{
    color: #887E7E;
    font-size: 26px;
    margin-bottom: 70px;
    text-align: center;
}

.statistics-offer-content{
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 40px;
    text-align: center;
}

.statistics-offer-available{
    color: #0B50A4;
    font-weight: 700;
    margin-bottom: 40px;
}

.statistics-offer-available-button{
    background-color: #0B50A4;
    border-radius: 50px;
    color: white;
    font-weight: 700;
    padding: 20px;
    font-weight: 600;
}

.statistics-offer-available-button-pink{
    background-color: #E984B7;
    color: black;
}

.statistics-offer-available-button-yellow{
    background-color: #F3C121;
    color: black;
}

.stats-charts-container{
    background-color: white;
    padding: 20px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.avatar-name-block{
    display: flex;
    background-color: white;
    width: 40px;
    border-radius: 40px;
}

.avatar-name {
    align-items: center;
    color: #ee4447;
    display: flex;
    font-size: 20px;
    font-weight: 700;
    height: 40px;
    justify-content: center;
    width: 40px;
}

.statistics .avatar-name {
    color: #0B50A4;
}

.profile .avatar-name {
    color: #E984B7;
}

.stats-charts-head-title{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 40px;
}

.profile-title{
    font-size: 36px;
    margin: 30px 30px 0 30px;
    padding-bottom: 30px;
    text-align: center;
    border-bottom: 1px solid #C4C4C4;
    display: flex;
    flex: 1;
    max-height: 50px;
}

.profile-title-text{
    display: flex;
    flex: 1;
    justify-content: center;
}

.profile-detail{
    background-color: #F5F4ED;
    margin-left: 240px;
    display: flex;
    flex-direction: column;
    min-width: calc(100% - 240px);
}

.dashboard-block{
    margin-left: 240px;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    flex: 1;
}

.menu-item-icon{
    width: 24px;
    height: 24px;
}

.profile-menu-item{
    min-height: 40px;
    display: flex;
    align-items: center;
    color: #887E7E;
    flex: 1;
}

.profile-menu-item-active{
    color: black;
}

.profile-menu-item-active::before {
    content: "";
    display: block;
    position: absolute;
    height: 30px;
    width: 5px;
    background-color: #E984B7;
    margin-left: -10px;
  }

.profile-menu-item-img{
    display: flex;
    width: 40px;
    margin-right: 15px;
    background-color: #0B50A410;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.profile-menu-item-title{
    display: flex;
    flex: 1;
    font-size: 17px;
}

.profile-menu-item-access{
    display: flex;
}

.logout-confirm{
    border-radius: 50px;
    background: #E984B7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 50px;
    font-size: 20px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    margin-bottom: 25px;
    cursor: pointer;
}

.logout-confirm-title{
    font-weight: 500;
    color: #181D27;
    font-size: 20px;
    margin-bottom: 30px;
    margin-top: 25px;
}

.logout-cancel  {
    border-radius: 50px;
    border: 1px solid #E984B7;
    background: #FFF;
    height: 50px;
    font-size: 20px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
}

.profile-form div.MuiInputBase-formControl{
    margin-bottom: 15px;
}

.profile-form input[type="text"],
.profile-form input[type="password"]{
    width: 330px;
    background-color: white;
}

.profile {
    .profile-help-link {
        width: 400px;
        background-color: white;
        color: black;
        border-radius: 5px;
        border: 1px solid black;
        margin: 10px 0;
        padding: 10px;

        .profile-help-link-text {
            width: 90%;
        }
    }
}

input.user-submit{
    width: 400px;
    height: 42px;
    border-radius: 50px;
    background: #E984B7;
    border: 0;
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
}

input.user-cancel{
    width: 400px;
    height: 42px;
    border-radius: 50px;
    border: 1px solid #E984B7;
    background: #FFF;
    font-size: 20px;
    font-weight: 600;
}

.profile-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin: 2rem;
    flex: 1;
}

.profile-global{
    display: flex;
    flex: 1;
    width: 100%;
    padding: 40px;
}

.menu-hamburger, .profile-avatar, .profile-logo, .mobile-menu, .charts-mobile{
    display: none;
}

.mobile-menu{
    position: fixed;
    background-color: white;
    min-height: 100%;
    min-width: 240px;
    z-index: 1;
    padding: 15px;
    flex-direction: column;
}

.mobile-menu-logo{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 100px;
}

.mobile-menu-item{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 40px;
}

.mobile-menu-item-active{
    font-weight: 900;
    text-decoration-line: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 10px;
}

.mobile-menu-icon{
    margin-right: 30px;
}

.mobile-menu-logo img{
    height: 52px;
}

.notification-title {
    width: 60%;
    padding-left: 40px;
    display: inline-block;
}
.notification-select {
    width: 20%;
    display: inline-block;
    text-align: center;
}

.affluence-preview{
    margin-top: 40px;
}

.chart-rate{
    font-weight: 700;
}

.chart-rate-success{
    color: #018C4E;
}

.chart-rate-warning{
    color: #F08324;
}

.chart-rate-danger{
    color: #EE4447;
}

.statistics-overview ,
.calendar-datepicker {
    .MuiOutlinedInput-root {
        background-color: #fff;
        border-radius: 20px;
    }
}

@media screen and (max-width: 512px){
    .label-full{
        margin-bottom: 5px;
    }

    .dashboard-resume-full{
        min-width: 180px;
        width: 45%;
    }

    .dashboard-resume{
        width: auto;
        padding: 10px;
        max-height: 100px;
        margin: 20px 5px;
    }

    .dashboard-estimated-affluence{
        max-width: 24%;
    }

    .dashboard-affluence{
        max-height: 400px;
        width: calc(100% - 30px);
    }

    .dashboard-content, .statistics-content{
        flex-direction: row!important;
    }

    .dashboard-selection-number, .dashboard-selection-label, .dashboard-selection-count{
        font-size: 14px;
    }

    .dashboard-selection-label{
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 2px;
    }

    .dashboard-selection-number{
        margin-right: 5px;
    }

    .global-stats-container{
        flex-direction: column;
    }

    .charts-mobile{
        display: flex!important;
    }

    .statistics-body{
        padding: 0 15px 15px 15px!important;
    }

    .statistics-overview{
        border-radius: 20px;
        padding: 15px 0px;
        flex-wrap: wrap;
    }

    .affluence-preview{
        margin-top: 0px;
    }

    .statistic-details{
        width: calc(33% - 10px);
        height: auto;
        text-align: center;
        padding: 5px 10px;
        margin: 5px;
    }

    .statistics-content .d-flex{
        flex-direction: column;
        min-width: 100%;
    }

    .stats-charts-container{
        margin: 20px 0 0 0;
    }

    .statistics-content{
        margin: 0;
    }

    .statistic-details-review{
        width: calc(45% - 10px);
        height: auto;
        text-align: center;
        padding: 5px 10px;
        min-height: 100px;
    }

    .statistic-details-review .statistic-detail-value{
        margin-bottom: 15px;
        display: flex;
        align-items: end;
    }

    .statistic-detail-meal span:nth-child(2){
        font-size: 16px;
    }

    .statistic-detail-title{
        font-size: 13px;
    }

    .statistic-detail-evolution{
        display: flex;
        justify-content: center;
        font-size: 10px;
    }

    .statistic-detail-evolution i, .mobile-hidden{
        display: none;
    }

    .statistic-detail-value, .statistic-meal-detail-value{
        display: flex;
        flex: 1;
    }

    .statistic-meal-detail-value{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .statistic-details-meal .statistic-detail-title{
        color: #635C5C;
        font-weight: 400;
    }

    .statistic-details-meal .statistic-detail-evolution{
        font-size: 14px;
    }

    .statistic-detail-value img, .statistic-meal-detail-value img{
        width: 24px;
        height: 24px;
    }

    .statistic-detail-meal.statistic-detail-value span{
        max-width: 65px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .statistic-meal-detail-value img{
        margin: 0;
    }

    .statistic-detail-value, .statistic-meal-detail-value{
        font-size: 16px;
    }

    .div-change-canteen{
        display: none!important;
    }

    .statistics-body{
        padding: 0 15px;
    }

    .statistics-sub-heading-text{
        padding: 0 15px;
        font-size: 16px;
        border-right: 1px solid black;
    }

    .statistics-heading{
        padding: 20px 0;
        border: 0;
    }

    .statistics-sub-heading{
        margin-top: 0;
        margin-bottom: 25px;
    }

    .dashboard-block, .global-stat{
        margin-left: 0!important;
    }

    .mobile-menu a{
        flex: 0;
    }

    .profile-global{
        flex-direction: column;
    }

    .menu-hamburger, .profile-avatar, .profile-logo{
        display: flex;
    }
    
    .profile-logo{
        justify-content: center;
        margin-top: 50px;
    }

    .profile-title{
        margin: 0;
        padding: 10px;
        background-color: #E984B7;
        color: black;
        font-size: 16px;
        font-weight: 900;
    }

    .profile-content{
        display: none;
    }

    .profile-detail{
        margin-left: 0;
    }

    .dashboard-chart, .statistics-chart{
        min-width: 225px;
        min-height: 150px;
    }

    .recharts-label-list, .recharts-cartesian-axis-ticks{
        font-size: 13px;
    }

    .recharts-wrapper{
        width: 215px;
        height: 140px;
    }

    .calendar-day, .current-day{
        width: 70px;
        height: 50px;
    }

    .current-day{
        height: 56px;
    }

    .dashboard-heading, .statistics-heading{
        font-size: 24px;
    }

    .dashboard-title{
        display: none !important;
    }

    .dashboard-resume span.number{
        margin-top: 15px;
        font-size: 30px;
    }

    .dashboard-resume span.label, .dashboard-resume span.label, .dashboard-resume div.label{
        font-size: 16px;
        font-weight: 500;
    }

    .dashboard-resume span.evolution{
        font-size: 12px;
    }
}

@media screen and (max-width: 768px){
    .div-change-canteen{
        display: none!important;
    }

    .statistics-body{
        padding: 0 15px;
    }

    .statistics-sub-heading-text{
        padding: 0 15px;
        font-size: 16px;
        border-right: 1px solid black;
    }

    .statistics-heading{
        padding: 20px 0;
        border: 0;
    }

    .statistics-sub-heading{
        margin-top: 0;
        margin-bottom: 25px;
    }

    .dashboard-block, .global-stat{
        margin-left: 0!important;
        width: 100%;
    }

    .charts-desktop{
        display: none;
    }
}

@media screen and (max-width: 1000px){
    .day-m, .menu{
        display: none !important;
    }

    .dashboard-header-mobile, .statistics-header-mobile{
        display: flex;
    }

    .div-change-canteen{
        display: none!important;
    }

    .statistics-body{
        padding: 0 15px;
    }

    .statistics-sub-heading-text{
        padding: 0 15px;
        font-size: 16px;
        border-right: 1px solid black;
    }

    .statistics-heading{
        padding: 20px 0;
        border: 0;
    }

    .statistics-sub-heading{
        margin-top: 0;
        margin-bottom: 25px;
    }

    .dashboard-block, .global-stat{
        margin-left: 0!important;
    }
    
    .day-l{
        display: none !important;
    }
}

@media screen and (max-width: 1200px){
    .day-xl{
        display: none !important;
    }

    .dashboard-content, .statistics-content{
        flex-direction: column;
    }

    .dashboard-content div.summary{
        flex-direction: row;
    }
}

@media screen and (max-width: 1400px){
    .day-xxl{
        display: none !important;
    }
}
