.settings {
    .sub-title {
        color: #887E7E;
        font-size: 17px;
        margin-bottom: 10px;
    }

    .btn-choose-canteen-price:checked {
        border: 0;
        accent-color: $profile-bg-color;
    }

    .choose-canteen {
        margin: 0 20px;
    }

    .days-open {
        flex-wrap: wrap;

        label {
            margin: 10px;

            input[type="checkbox"] {
                display: none;

                &:checked + span {
                    border-color: $profile-bg-color;
                }
            }

            .day-open {
                border: 2px solid #000;
                border-radius: 10px;
                cursor: pointer;
                background-color: #fff;
                padding: 25px 20px;
                min-width: 130px;
                text-align: center;
                display: flex;
                justify-content: center;
            }
        }
    }

    .days-opening {
        width: 90%;
        border-top: 1px solid #CACACA;
        border-bottom: 1px solid #CACACA;
        padding: 20px 0;
        margin: 20px 0;

        .canteen-slot {
            border: 0;
            border-radius: 20px;
            background-color: rgba(233, 132, 183, 0.5);
            padding: 5px 10px;
        }
    }
}

.global-stat{
    margin-left: 240px;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    flex: 1 1;
}
