.login{
    background: #EE4447;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #F5F4ED;
    padding: 30px 15px;
    min-height: 100vh;
}

.login-subtext{
    margin-top: 25px;
    margin-bottom: 60px;
    font-size: 24px;
    font-weight: 900;
}

.login-form{
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.50);
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 90%;
    align-items: center;

    &-headtitle{
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 30px;
        text-align: center;
    }
}

.login-template {
    justify-content: center;
    align-items: center;
    max-width: 450px;
    margin: 0 auto;

    .login-field {
        min-width: 300px;
        max-width: 400px;
        padding: 10px 15px;
        margin: 10px 0;
        border-radius: 5px;
        border: 1px solid #ECE9EC;
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
        font-family: Outfit;
        background: transparent;
        color: white;

        &::placeholder {
            color: white;
        }

        &:focus{
            outline: none;
        }
    }

    .login-submit {
        max-width: 400px;
        min-width: 300px;
        padding: 10px;
        background-color: $login-btn-color;
        font-weight: bold;
        margin: 20px 0;
        border: 0;
        border-radius: 50px;
    }

    .login-forgot-pass {
        max-width: 400px;
        min-width: 300px;
        width: 90%;
        padding-bottom: 15px;
        text-align: center;

        a {
            text-decoration: none;
            color: inherit;
            justify-content: center;
            font-family: 'Outfit';
            padding-bottom: 10px;
            border-bottom: 1px solid white;
        }
    }

    .login-app{
        a{
            text-decoration: none;
            color: inherit;
            justify-content: center;
            font-size: 12px;
            font-weight: 700;
            text-decoration-line: underline;
            margin: 25px;
        }
    }

    .login-new {
        padding-top: 20px;
        max-width: 400px;
        min-width: 300px;
        width: 90%;

        a {
            color: inherit;
        }
    }

    form {
        max-width: 400px;
        min-width: 300px;
        width: 90%;
    }

    .login-back {
        padding-bottom: 20px;

        a:hover {
            text-decoration: underline;
            color: #FFF;
        }
    }
}
