.logo {
    width: 100%;
    height: 100%;
}

.admin-dashboard {
    .menu, .menu-item__active:after {
        background-color: $canteen-setting-btn-color;
        color: white;
    }
}

.dashboard {
    .menu, .menu-item__active:after {
        background-color: $dashboard-bg-color;
    }
}

.unsold-meals {
    .menu, .menu-item__active:after {
        background-color: $unsold-meals-bg-color;
    }
}

.statistics {
    .menu, .menu-item__active:after {
        background-color: $statistics-bg-color;
    }
}

.profile {
    .menu, .menu-item__active:after {
        background-color: $profile-bg-color;
    }
}

.menus {
    .menu, .menu-item__active:after {
        background-color: $menus-bg-color;
    }
}

.communications {
    .menu, .menu-item__active:after {
        background-color: $communications-bg-color;
    }
}

.community {
    .menu, .menu-item__active:after {
        background-color: $community-bg-color;
    }
}

.menu-items-block {
    flex: 1;

    .menu-items {
        padding: 0;

        a {
            color: inherit;
            text-decoration: none;
        }
    }

    .menu-item {
        margin-bottom: 30px;

        &.menu-item__active {
            border-bottom:2px solid black;
            position: relative;

            &:after {
                padding: 0;
                margin: 0;
                display: block;
                content: "";
                width: 50%;
                height: 2.1px;
                position: absolute;
                right: 0;
                bottom: -2px;
            }
        }
    }
}
